@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Chewy);

html,
body {
  font-family: 'Chewy', sans-serif;
}

* {
  font-family: inherit;
}
